import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import StoreContext from './../../../store'

const Comp = (props) => {
  const store = useContext(StoreContext);
  const [event_group, setEvent] = useState([]);
  const [active_comp, setActive] = useState(store.getItem("active_comp"));

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    if (store.getItem("event_obj")) {
      setEvent(
        groupBy(
          store.getItem("event_obj").filter((v) => props.mode_id === v.event_type_id)
          , 'competition_name')
      );
    }
  }, [props.mode_id, store]);

  const Complist = ({ data }) =>
    Object.entries(data).map(([k, v]) => (
      <li key={v[0].competition_id}>
        <NavLink
          to="#sidebarMultilevel2"
          data-bs-toggle="collapse"
          className=""
          aria-expanded="true"
          onClick={(e) => {
            if (active_comp !== k) {
              setActive(k)
              store.setItem("active_comp", k);
            } else {
              setActive("")
              store.setItem("active_comp", "");
            }
          }}
        >
          {k} <span className="menu-arrow" />
        </NavLink>
        {active_comp &&
          <div className="collapse show">
            <ul className="nav-second-level">
              {
                event_group[k].map((v1, k) =>
                  active_comp === v1.competition_name &&
                  <li key={v1.market_id}>
                    <NavLink to={`/eventdata/${props.mode_id}/${v1.event_id}/${v1.market_id}/`}>{v1.name}</NavLink>
                  </li>
                )}
            </ul>
          </div>
        }
      </li>
    ));
  return (<ul className="nav-second-level">
    {event_group && <Complist data={event_group} {...props} />}
  </ul>)
}
export default Comp