/* eslint-disable no-unused-vars */
import React from "react";
import { NavLink } from "react-router-dom";
import StoreContext from '../../../store';
import "../../../css/sidemenu.scss";
import { GiTennisRacket, GiCricketBat, GiCardPlay } from "react-icons/gi";
import { IoIosFootball, IoMdHeart } from "react-icons/io";
import * as serviceWorkerRegistration from './../../../serviceWorkerRegistration';
import Comp from "./Com";

class SideMenu extends React.Component {
  static contextType = StoreContext;
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      active_mode: props.modeid ? props.modeid : 0,
    };
  }
  componentDidUpdate() {
    if (!this.state.open) {
      this.setState({
        open: true
      })
    }
  }
  refreshCacheAndReload = () => {
    if ('serviceWorker' in navigator) {
      serviceWorkerRegistration.unregister();
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      }).then(() => {
        serviceWorkerRegistration.register();
      })
    }
    setTimeout(function () { window.location.replace(""); }, 100)
  }
  render() {
    let LIST_GROP = [];
    if (this.props.play_mods && this.props.play_mods.length > 0) {
      LIST_GROP = this.props.play_mods.map((v, k) => (
        <li key={k}>
          <NavLink onClick={() => {
            this.setState({
              open: false
            }, () => { this.props.onMenuToggle() })
          }} title={v.name} to={`/` + v.name.toLowerCase()}>
            <span className="sport-icon">
              {v.id === 4 && <GiCricketBat></GiCricketBat>}
              {v.id === 2 && <GiTennisRacket></GiTennisRacket>}
              {v.id === 1 && <IoIosFootball></IoIosFootball>}
              {v.id === 10 && <GiCardPlay></GiCardPlay>}
            </span>
            {v.name}
          </NavLink>
        </li>
      ));
    }
    return (
      <div className={`left-side-menu  ${this.props.isMenuOpen ? "d-block" : "d-none"}`}>
        <div className="h-100" data-simplebar="init">
          <div className="simplebar-wrapper" style={{ margin: 0 }}>
            <div className="simplebar-height-auto-observer-wrapper">
              <div className="simplebar-height-auto-observer" />
            </div>
            <div className="simplebar-mask">
              <div className="simplebar-offset" style={{ right: 0, bottom: 0 }}>
                <div
                  className="simplebar-content-wrapper"
                  tabIndex={0}
                  role="region"
                  aria-label="scrollable content"
                  style={{ height: "100%", overflow: `${this.props.isMenuOpen ? "scroll" : "hidden"}` }}

                >
                  <div className="simplebar-content" style={{ padding: 0 }}>
                    {/* User box */}
                    <div className="user-box text-center">
                      <img
                        src="assets/images/users/user-1.jpg"
                        alt="user-img"
                        title="Mat Helme"
                        className="rounded-circle avatar-md"
                      />

                      <p className="text-muted">Admin Head</p>
                    </div>
                    {/*- Sidemenu */}
                    <div id="sidebar-menu">
                      <ul id="side-menu">
                        <li>
                          <NavLink to="#my market">
                            <i className="mdi mdi-google-glass" />
                            <span> my market </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#favourite">
                            <i className="mdi mdi-bookmark" />
                            <span> favourite </span>
                          </NavLink>
                        </li>
                        <li className="menu-title">
                          <i className="mdi mdi-chevron-double-right" /> sports
                          exchange
                        </li>
                        {this.props.play_mods &&
                          this.props.play_mods
                            .map((v, k) => (
                              <li key={k}>
                                <NavLink
                                  to={`#/${k}`}
                                  data-bs-toggle="collapse"
                                  className=""
                                  aria-expanded="true"
                                  onClick={(e) => {
                                    let mode_id = 0;
                                    if (this.state.active_mode !== v.id) {
                                      mode_id = v.id;
                                    }
                                    this.setState({
                                      active_mode: mode_id
                                    })
                                  }}
                                >
                                  <i className="mdi mdi-cricket" />
                                  <span> {v.name} </span>
                                  <span className="menu-arrow" />
                                </NavLink>
                                <div className="collapse show" id="sidebarMultilevel" style={{}}>
                                  {this.state.active_mode === v.id && <Comp event_obj={this.state.event_obj} {...this.props} mode_id={v.id} />}
                                </div>
                              </li>
                            ))}
                        {this.context.getItem("username") &&
                          <li>
                            <NavLink
                              onClick={(e) => {
                                this.context.setItem("username", "")
                              }}
                              to={`/logout`}
                              data-bs-toggle="collapse"
                              className=""
                              aria-expanded="true"

                            >
                              <i className="mdi mdi-cricket" />
                              <span> Logout </span>
                              <span className="menu-arrow" />
                            </NavLink>

                          </li>}
                      </ul>
                    </div>
                    {/* End Sidebar */}
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="simplebar-placeholder"
              style={{ width: "auto", height: 417 }}
            />
          </div>
          <div
            className="simplebar-track simplebar-horizontal"
            style={{ visibility: "hidden" }}
          >
            <div
              className="simplebar-scrollbar"
              style={{ width: 0, display: "none" }}
            />
          </div>
          <div
            className="simplebar-track simplebar-vertical"
            style={{ visibility: "visible" }}
          >
            <div
              className="simplebar-scrollbar"
              style={{
                height: 75,
                display: "block",
                transform: "translate3d(0px, 0px, 0px)"
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SideMenu;
