import React from "react";
import { Routes, Route, Navigate,useNavigate } from "react-router-dom";
import Main from "../containers/layouts/Main";
import Report from "../containers/layouts/Report";

// views
import EventController from "../containers/views/Main/Event/EventController";
import Login from "../containers/views/Main/Login";

// admin Views

const RT = () => {
  let play_mods = [];
  let play_mods_str = localStorage.getItem("client_play_mods") ? localStorage.getItem("client_play_mods") : "";
  let navigate = useNavigate();

  if (play_mods_str) {
    play_mods = JSON.parse(play_mods_str);
  }
  return (
    <Routes>
      <Route path="/" element={<Login navigate={navigate}/>} />
      <Route path="/login" element={<Login navigate={navigate}/>} />
      <Route path="/*" element={<Main play_mods={play_mods} />} />
      <Route path="/report/*" element={<Report play_mods={play_mods} />} />
      <Route
        path="/eventdata/*"
        element={<EventController key={0} />}
      />
      <Route
        path="/eventdata/:modeid/:eventid/:marketid"
        id=":eventid"
        element={<EventController key={1} />}
      />
      <Route
        path="/mymarket/*"
        element={<EventController key={2} />}
      />
      <Route
        path="/logout/*"
        element={<Navigate to={`/login?` + Math.random()} key={3} />}
      />

    </Routes>
  );
};


export default RT;