import React, { useState } from "react";
import TopHeader from "../../components/Common/Header/TopHeader";
import TopNav from "../../components/Common/Header/TopNav";
import SideMenu from "../../components/Common/Sidebar/index";
import { NavLink } from "react-router-dom";

const EventLayout = (props) => {
  const [isMenuOpen, setMenu] = useState(window.innerWidth > 767 ? true : false);
  const toggleMenu = () => {
    setMenu(!isMenuOpen)
    let body = document.body;
    if (isMenuOpen) {
      if (window.innerWidth > 767) body.setAttribute("data-sidebar-size", "condensed");
      else body.classList.remove('sidebar-enable')
    } else {
      if (window.innerWidth > 767) body.setAttribute("data-sidebar-size", "default");

      else { body.classList.add('sidebar-enable') }
    }
  }
  
  return (
    <React.Fragment>
      <TopHeader {...props} toggleMenu={toggleMenu}>
        <NavLink to={`/inplay`} className="nav-logo">
          {process.env.REACT_APP_NAME}
        </NavLink>
      </TopHeader>
     <TopNav  {...props}></TopNav>
      <div className="commentary">
        <span>
          <i className="mdi mdi-volume-source" />
        </span>
        <marquee>{props.message}</marquee>
      </div>
      <SideMenu
        isMenuOpen={isMenuOpen}
        onMenuToggle={toggleMenu}
        {...props}
      ></SideMenu>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            {props.children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventLayout;
