import React, { useState, useContext, useEffect } from "react";
import StoreContext from '../../../store';
import { NavLink } from "react-router-dom";

const TopHeader = (props) => {

  const [showmenu, setMenu] = useState(false);
  const store = useContext(StoreContext);
  const [username, setUserdata] = useState(store.getItem("username"));

  useEffect(() => {
    setUserdata(store.getItem("username"))
  }, [store])

  return (
    <div className="navbar-custom">
      <div className="container-fluid">
        <ul className="list-unstyled topnav-menu float-end mb-0">
          <li className="dropdown d-inline-block d-lg-none">
            <NavLink
              className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
              data-bs-toggle="dropdown"
              to="#drop"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i className="mdi mdi-search-web noti-icon" />
            </NavLink>
            <div className="dropdown-menu dropdown-lg dropdown-menu-end p-0">
              <form className="p-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
              </form>
            </div>
          </li>
          {username &&
            (<><li className="melr">
              <NavLink to="#bal" className="nav-link me-0 waves-effect waves-light">
                <span>
                  main balance <b className="profit">({username && username.available_balance})</b>
                </span>
              </NavLink>
            </li><li className="melr">
                <NavLink to="#bal" className="nav-link me-0 waves-effect waves-light">
                  <span>
                    exposure <b className="loss">({username && username.expose})</b>
                  </span>
                </NavLink>
              </li><li className="dropdown notification-list topbar-dropdown">
                <NavLink
                  className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
                  data-bs-toggle="dropdown"
                  to="#menu"
                  onClick={() => {
                    setMenu(!showmenu)
                  }}
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <span className="pro-user-name">
                    {username && username.username} <i className="mdi mdi-chevron-down" />
                  </span>
                </NavLink>
                <div className={"dropdown-menu dropdown-menu-end profile-dropdown " + (showmenu ? 'show' : 'hidden')} >
                  <div className="melr">
                    <span>
                      main balance <b className="profit">({username && username.available_balance})</b>
                    </span>
                  </div>
                  <div className="melr">
                    <span>
                      exposure <b className="loss">({username && username.expose})</b>
                    </span>
                  </div>
                  <NavLink to="/report/profile" className="dropdown-item notify-item">
                    <span>profile</span>
                  </NavLink>
                  <NavLink to="/report/buttons" className="dropdown-item notify-item">
                    <span>stake setting</span>
                  </NavLink>
                  <NavLink to="/report/accountstatement" className="dropdown-item notify-item">
                    <span>account statement</span>
                  </NavLink>
                  <NavLink to="/report/profitloss" className="dropdown-item notify-item">
                    <span>profit &amp; loss</span>
                  </NavLink>
                  <NavLink to="/report/mybets" className="dropdown-item notify-item">
                    <span>bet history</span>
                  </NavLink>
                  <NavLink to="/report/changepassword" className="dropdown-item notify-item">
                    <span>change password</span>
                  </NavLink>
                  <NavLink to="/report/rules" className="dropdown-item notify-item">
                    <span>rules</span>
                  </NavLink>
                  {/* item*/}
                  <NavLink
                    onClick={(e) => {
                      store.setItem("username", "")
                    }}
                    to="/logout"
                    className="dropdown-item notify-item logout"
                  >
                    <i className="mdi mdi-logout" />
                    <span>Logout</span>
                  </NavLink>
                </div>
              </li></>)}
        </ul>
        {/* LOGO */}
        <div className="logo-box">
          <button className="button-menu-mobile waves-effect waves-light" onClick={() => {
            props.toggleMenu();
          }}>
            <i className="mdi mdi-menu" />
          </button>
          <NavLink to="/inplay" className="logo logo-dark text-center">
            <span className="logo-sm">
              <img src="assets/img/91logo.png" alt="" height={30} />
              {/* <span class="logo-lg-text-light">UBold</span> */}
            </span>
            <span className="logo-lg">
              <img src="/assets/img/91logo.png" alt="" height={30} />
              {/* <span class="logo-lg-text-light">U</span> */}
            </span>
          </NavLink>
          <NavLink to="/inplay" className="logo logo-light text-center">
            <span className="logo-sm">
              <img src="/assets/img/91logo.png" alt="" height={30} />
            </span>
            <span className="logo-lg">
              <img src="/assets/img/91logo.png" alt="" height={30} />
            </span>
          </NavLink>
        </div>
        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            {/* Mobile menu toggle (Horizontal Layout)*/}
            <NavLink
              className="navbar-toggle nav-link"
              data-bs-toggle="collapse"
              data-bs-target="#topnav-menu-content"
              to="#topnav-menu-content"
            >
              <div className="lines">
                <span />
                <span />
                <span />
              </div>
            </NavLink>
            {/* End mobile menu toggle*/}
          </li>
          <li className="d-none d-xl-block">
            <form className="app-search">
              <div className="app-search-box dropdown">
                <div className="input-group">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search..."
                    id="top-search"
                  />
                  <button className="btn input-group-text" type="submit">
                    <i className="mdi mdi-search-web" />
                  </button>
                </div>
                <div className="dropdown-menu dropdown-lg" id="search-dropdown">
                  <NavLink to="#s1" className="dropdown-item notify-item">
                    <span>Searchlist 1</span>
                  </NavLink>
                  <NavLink to="#s1" className="dropdown-item notify-item">
                    <span>Searchlist 2</span>
                  </NavLink>
                  <NavLink to="#s1" className="dropdown-item notify-item">
                    <span>Searchlist 3</span>
                  </NavLink>
                </div>
              </div>
            </form>
          </li>
        </ul>
        <div className="clearfix" />
      </div>
    </div>

  );
};
export default TopHeader;
