import React from "react";
import { Route, Routes } from "react-router-dom";
import ModeController from "../views/Main/Home/ModeController";

const Main = (props) => {
  let ROUTE_GROP = [];
  if (props.play_mods && props.play_mods.length > 0) {
    ROUTE_GROP = props.play_mods.map((v, k) => (
      <Route
        key={k}
        path={`/` + v.name.toLowerCase()}
        element={<ModeController {...props} modeid={v.id} key={v.id} />}
      />
    ));
  }
  return (
    <React.Fragment>
        <Routes>
          <Route
            path="/other"
            element={<ModeController modeid={6} key={6} />}
          />
          <Route
            path="/inplay"
            element={<ModeController  modeid={5} key={5} />}
          />
          {ROUTE_GROP.length > 0 && ROUTE_GROP}
          <Route element={<div>404 Page Not Found</div>} exact path="/*" />
        </Routes>
    </React.Fragment>
  );
};

export default Main;
