import PusherJs from 'pusher-js';

const Pusher = new PusherJs(process.env.REACT_APP_PUSHER_APP_KEY, {
    auth: {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt") || "",
        }
    },
    authEndpoint: process.env.REACT_APP_API_HOST + '/socket_auth',
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
    key: process.env.REACT_APP_PUSHER_APP_KEY,
});


export default Pusher