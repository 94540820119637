import React from "react";
import { Route, Routes } from "react-router-dom";
import ReportController from "../views/Main/Report/ReportController";
import { Store } from './../../store';

const Report = (props) => {

  return (
    <Store>
      <Routes>
        <Route element={<div>404 Page Not Found</div>} exact path="/*" />
        <Route
          path="/accountstatement"
          element={<ReportController reportid={1} key={1} />}
        />
        <Route
          path="/accountsummary"
          element={<ReportController reportid={15} key={15} />}
        />
        
        <Route
          path="/mybets"
          element={<ReportController reportid={2} key={2} />}
        />
        <Route
          path="/profitloss"
          element={<ReportController reportid={3} key={3} />}
        />
        <Route
          path="/casinoresult"
          element={<ReportController reportid={4} key={4} />}
        />
        <Route
          path="/termsncodition"
          element={<ReportController reportid={5} key={5} />}
        />
        <Route
          path="/rules"
          element={<ReportController reportid={6} key={6} />}
        />{" "}
        <Route
          path="/profile"
          element={<ReportController reportid={7} key={7} />}
        />
        <Route
          path="/changepassword"
          element={<ReportController reportid={8} key={8} />}
        />
        <Route
          path="/chipsummary"
          element={<ReportController reportid={9} key={9} />}
        />
        <Route
          path="/buttons"
          element={<ReportController reportid={10} key={10} />}
        />
        
      </Routes>
    </Store>
  );
};

export default Report;
