import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import EventLayout from "../../../layouts/EventLayout"
import Pusher from "../../../../Helper/Pusher"
import StoreContext from './../../../../store'

const EventPage = React.lazy(() => import("./index"));
const MyMarket = React.lazy(() => import("./MyMarket"));

const EventController = () => {

  const store = useContext(StoreContext);
  const { eventid, marketid, modeid } = useParams();
  const [tmp_exp_update, setExpUpdate] = useState({})
  let [message, setMarquee] = useState(store.getItem("message"));

  useEffect(() => {

    const id = store.getItem("username") ? store.getItem("username").id : 0;

    const GB = Pusher.subscribe('other');
    GB.bind('GlobalMessageUpdated', data => {
      console.log("GlobalMessageUpdated", data)
    });

    const PU = Pusher.subscribe('private-user.' + id);

    PU.bind('UserAccountUpdated', data => {
      const userdata = store.getItem("username");
      userdata.balance = data.balance
      userdata.expose = data.expose
      store.setItem("username", userdata);
    });

    const EV = Pusher.subscribe('event-' + eventid);

    EV.bind('ExposeUpdated', data => {
      console.log("event ExposeUpdated", data)
    });

    const PE = Pusher.subscribe('private-expose.' + eventid + "." + id);

    PE.bind('ExposeUpdated', data => {
      setExpUpdate(data)
    });

    return () => {
      Pusher.unsubscribe('other')
      Pusher.unsubscribe('event-' + eventid);
      Pusher.unsubscribe('private-user.' + id);
      Pusher.unsubscribe('private-expose.' + eventid + "." + id);
    };
  }, [eventid]);

  let event_d_str = localStorage.getItem(eventid);
  let eventdata = [];
  if (event_d_str) {
    eventdata = JSON.parse(event_d_str);
  }

  let [eventname, setEvent] = useState(eventdata.eventname ? eventdata.eventname : "");
  let bet_str = localStorage.getItem("betlist_" + eventid);
  let btns = [];
  let btns_str = localStorage.getItem("btns") ? localStorage.getItem("btns") : "";
  let fcp = localStorage.getItem("fcp") ? localStorage.getItem("fcp") : "";
  const role = localStorage.getItem("rl") ? parseInt(localStorage.getItem("rl")) : "";
  let ps = localStorage.getItem("ps") ? localStorage.getItem("ps") : "";
  let play_mods = [];
  let play_mods_str = localStorage.getItem("client_play_mods") ? localStorage.getItem("client_play_mods") : "";
  if (play_mods_str) {
    play_mods = JSON.parse(play_mods_str);
  }
  let betdata = [];
  if (bet_str) {
    betdata = JSON.parse(bet_str);
  }
  if (btns_str) {
    btns = JSON.parse(btns_str);
  }
  if (parseInt(fcp) === 1) {
    window.location.href = process.env.PUBLIC_URL + "/report/changepassword";
    return null;
  }
  const setEventName = (nm) => {
    setEvent(nm)
  }
  const flushTmp = () => {
    setExpUpdate({})
  }
  const updateMrq = (msg) => {
    setMarquee(msg);
  }
  if (marketid && eventid) {

  } else {
    let mymarket = localStorage.getItem("mymarket") ? JSON.parse(localStorage.getItem("mymarket")) : "";
    let marketid = [];
    for (let i = 0; i < mymarket.length; i++) {
      if (!marketid.includes(mymarket[i].market_id)) {
        marketid.push(mymarket[i].market_id)
      }
    }
    setEvent("My Markets");
  }

  return (
    <EventLayout eventname={eventname} role={role} message={message} play_mods={play_mods} modeid={parseInt(modeid)}
      eventid={eventid}
      marketid={marketid}>
      <React.Suspense fallback={""}>
        {marketid && eventid ? <EventPage
          modeid={parseInt(modeid)}
          eventid={eventid}
          marketid={marketid}
          betdata={betdata}
          eventdata={eventdata}
          btns={btns}
          ps={ps}
          role={role}
          setEventName={setEventName}
          flushTmp={flushTmp}
          updateMrq={updateMrq}
          play_mods={play_mods}
          tmp_exp_update={tmp_exp_update}
        /> : <MyMarket
          marketid={marketid}
          modeid={parseInt(modeid)}
          betdata={betdata}
          eventdata={eventdata}
          btns={btns}
          role={role}
          ps={ps}
          flushTmp={flushTmp}
          play_mods={play_mods}
        />}
      </React.Suspense>
    </EventLayout>
  );
};
export default EventController;
