import React, { useState, useContext,useEffect } from "react";

import EventLayout from "../../../layouts/EventLayout";
import Pusher from "../../../../Helper/Pusher"
import StoreContext from './../../../../store'

const ModePage = React.lazy(() => import("./index"));
const CasinoPage = React.lazy(() => import("./casino"));

const ModeController = (props) => {

  const store = useContext(StoreContext);
  let [exp, setExp] = useState(localStorage.getItem("exp") ? localStorage.getItem("exp") : "");
  let [bal, setBal] = useState(localStorage.getItem("bal") ? localStorage.getItem("bal") : "");
  let [message] = useState(store.getItem("message"));

  useEffect(() => {

    const id = store.getItem("username") ? store.getItem("username").id : 0;

    const GB = Pusher.subscribe('other');
    GB.bind('GlobalMessageUpdated', data => {
      console.log("GlobalMessageUpdated", data)
    });

    const EL = Pusher.subscribe('event-list');
    EL.bind('EventListUpdated', data => {
      console.log("EventListUpdated", data)
    });

    const PU = Pusher.subscribe('private-user.' + id);

    PU.bind('UserAccountUpdated', data => {
      const userdata = store.getItem("username");
      userdata.balance = data.balance
      userdata.expose = data.expose
      store.setItem("username", userdata);
    });

    return () => {
      Pusher.unsubscribe('other')
      Pusher.unsubscribe('event-list')
      Pusher.unsubscribe('private-user.' + id);
    };
  }, []);

  const updateBal = (obj) => {
    if ('exp' in obj) {
      setExp(obj.exp);
      localStorage.setItem("exp", obj.exp);
    }
    if ('bal' in obj) {
      setBal(obj.bal);
      localStorage.setItem("bal", obj.bal);
    }
  }


  const modeid = props.modeid;
  let mode_d_str = localStorage.getItem(modeid);
  let username = localStorage.getItem('username');
  let c_tkn = localStorage.getItem('client_casino_token');
  let eventlist = [];
  let fcp = localStorage.getItem("fcp") ? localStorage.getItem("fcp") : "";
  let ps = localStorage.getItem("ps") ? localStorage.getItem("ps") : "";
  let role = localStorage.getItem("rl") ? parseInt(localStorage.getItem("rl")) : "";
  let play_mods = [];
  let play_mods_str = localStorage.getItem("client_play_mods") ? localStorage.getItem("client_play_mods") : "";
  if (play_mods_str) {
    play_mods = JSON.parse(play_mods_str);
  }

  if (mode_d_str) {
    eventlist = JSON.parse(mode_d_str);
  }

  if (parseInt(fcp) === 1) {
    window.location.href = process.env.PUBLIC_URL + "/report/changepassword";
    return null;
  }

  return (
    <EventLayout role={role} message={message} bal={bal} exp={exp} play_mods={play_mods}>
      <React.Suspense fallback={""}>
        {modeid && modeid === 10 ? <CasinoPage
          modeid={modeid}
          ps={ps}
          role={role}
          play_mods={play_mods}
          username={username}
          casino_token={c_tkn}
          updateBal={updateBal}
        /> : <ModePage
          modeid={modeid}
          eventlist={eventlist}
          ps={ps}
          role={role}
          updateBal={updateBal}
          play_mods={play_mods}
        />}
      </React.Suspense>
    </EventLayout>
  );
};
export default ModeController;
