import React, { Component } from 'react'
const StoreContext = React.createContext()

export class Store extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        "bal": localStorage.getItem("bal") || "",
        "exp": localStorage.getItem("exp") || "",
        "active_user": JSON.parse(localStorage.getItem("active_user")) || {},
      }
    }
  }

  setItem = async (key, data, per = true) => {
    return new Promise((resolve, reject) => {
      try {
        let new_data = { ...this.state.data };
        if (key) {
          new_data[key] = data ? data : "";
          this.setState({
            data: new_data
          }, () => {
            if (typeof data === 'object') {
              data = JSON.stringify(data)
            }
            if (per) {
              localStorage.setItem(key, data)
            }
          })
        }
        resolve(data)
      } catch (error) {
        reject(false)
      }
    })
  }

  getItem = (key) => {
    let data = '';
    if (this.state.data[key]) {
      data = this.state.data[key];
    } else if (localStorage.getItem(key)) {
      data = localStorage.getItem(key)
    }
    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  }

  render() {

    const { children } = this.props
    const { data } = this.state
    const { getItem, setItem } = this

    return (
      <StoreContext.Provider
        value={{
          data,
          setItem,
          getItem
        }}
      >
        {children}
      </StoreContext.Provider>
    )
  }
}

export default StoreContext