import React from "react";
import { NavLink } from "react-router-dom";

const TopNav = (props) => {
  let LIST_GROP = [];

  LIST_GROP = props.play_mods && props.play_mods.map((v, k) => (
    <NavLink key={k} className="dropdown-item" title={v.play} to={`/` + v.name.toLowerCase()}>
      {v.name}
    </NavLink>
  ))
  return (
    <div className="topnav">
      <div className="container-fluid">
        <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
          <div className="collapse navbar-collapse" id="topnav-menu-content">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle arrow-none"
                  href="#exchange"
                  id="topnav-layout"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  exchange <div className="arrow-down" />
                </a>
                <div className="dropdown-menu" aria-labelledby="topnav-layout">
                  {LIST_GROP}
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#indian_games">
                  {" "}
                  indian games
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#virtual sports">
                  virtual sports
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#live casino">
                  live casino
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#sports games">
                  sports games
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#others">
                  others
                </a>
              </li>
            </ul>
            {/* end navbar*/}
          </div>
          {/* end .collapsed*/}
        </nav>
      </div>
      {/* end container-fluid */}
    </div>

  );
};
export default TopNav;
