import React, { useState } from "react";
import EventLayout from "../../../layouts/EventLayout";
const ProfitLoss = React.lazy(() => import("./ProfitLoss"));
const MyBets = React.lazy(() => import("./MyBets"));
const Profile = React.lazy(() => import("./Profile"));
const ChangePassword = React.lazy(() => import("./ChangePassword"));
const AccountStatement = React.lazy(() => import("./AccountStatement"));
const Buttons = React.lazy(() => import("./Buttons"));
const AccountSummary = React.lazy(() => import("./AccountSummary"));
const Rules = React.lazy(() => import("./Rules"));

const ReportController = (props) => {
  const username = localStorage.getItem("username")
    ? localStorage.getItem("username")
    : "";
  
  let chips = localStorage.getItem("chips") ? localStorage.getItem("chips") : "";
  let pl = localStorage.getItem("pl") ? localStorage.getItem("pl") : "";
  let play_mods = [];
  let play_mods_str = localStorage.getItem("client_play_mods") ? localStorage.getItem("client_play_mods") : "";
  let fcp = localStorage.getItem("fcp") ? localStorage.getItem("fcp") : "";
  let role = localStorage.getItem("rl") ? parseInt(localStorage.getItem("rl")) : "";
  let message = localStorage.getItem("message") ? localStorage.getItem("message") : "";
  let [count] = useState(JSON.parse(localStorage.getItem("count")));
  let [q_msg] = useState(JSON.parse(localStorage.getItem("q_msg")));

  if (play_mods_str) {
    play_mods = JSON.parse(play_mods_str);
  }
  if (parseInt(fcp) === 1 && props.reportid !== 8) {
    window.location.href = process.env.PUBLIC_URL + "/report/changepassword";
    return null;
  }
  return (
    <EventLayout
      bgClass="bg-primary-50 dark:bg-primary-700"
      message={message}
      role={role}
      play_mods={play_mods}
      count={count}
      q_msg={q_msg}
    >
      <React.Suspense fallback={""}>
        {props && props.reportid === 1 && (<AccountStatement role={role} play_mods={play_mods} />)}
        {props && props.reportid === 2 && (<MyBets chips={chips} pl={pl} username={username} role={role} play_mods={play_mods} />)}
        {props && props.reportid === 3 && (<ProfitLoss role={role} play_mods={play_mods} />)}
        {props && props.reportid === 6 && (<Rules chips={chips} pl={pl} username={username} role={role} />)}
        {props && props.reportid === 7 && (<Profile chips={chips} pl={pl} username={username} role={role} />)}
        {props && props.reportid === 8 && (<ChangePassword chips={chips} pl={pl} username={username} role={role} />)}
        {props && props.reportid === 10 && (<Buttons chips={chips} pl={pl} username={username} role={role} />)}
        {props && props.reportid === 15 && (<AccountSummary role={role} play_mods={play_mods} />)}
      </React.Suspense>
    </EventLayout>
  );
};

export default ReportController;
