import React from "react";
import Routers from "./router";
import NetworkDetector from "./Helper/NetworkDetector";
import "./assets/css/config/material/bootstrap.min.css";
import "./assets/css/config/material/app.min.css";
import "./assets/css/icons.min.css";
import "./assets/css/themes.css"; 

function App() {
  return <Routers />;
}

export default NetworkDetector(App);
