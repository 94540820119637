import React from 'react';

const siteloader = {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: 'rgba(23, 22, 22,0.5)',
    pointerEvents: 'auto',
    backgroundClip: 'padding-box',
    zIndex: 99999,
    transition: 'opacity 0.3s linear',
    outline: 0,
    justifyContent: 'center',
    alignItems: 'center',
    margin: "auto",
    left:0,
    top:0
}

const Loader = () => {
    return (
        <div style={siteloader}>
            <div className="spinner-grow text-dark" role="status">
                <span className="sr-only">...</span>
            </div>
        </div>
    )
};

export default Loader;