import React, { Component } from "react";
import { FaTelegram } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import "./../../../../css/login.scss";
import EventLayout from "../../../layouts/EventLayout"
import Loader from "../../../../utilities/loader";
import * as serviceWorkerRegistration from './../../../../serviceWorkerRegistration';

const EMAIL = process.env.REACT_APP_ADMIN_EMAIL || 'yourEmailId@protonmail.com';
const TELEGRAM = process.env.REACT_APP_ADMIN_TELEGRAM || 'https://telegram.me/veerajsportsnews';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggenin: false,
      username: "",
      password: "",
      has_error: false,
      loading: false,
      remember: false
    };
  }

  componentDidMount() {
    var remember = JSON.parse(localStorage.getItem('remember')) || false;
    this.setState({
      remember: remember,
    }, () => {
      localStorage.clear();
      localStorage.setItem('remember', remember);
    })
    if (!remember) {
      setTimeout(() => {
        this.setState({
          password: ""
        })
      }, 1000)
    }

  }

  handleClick = (e) => {
    e.preventDefault();
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("username", this.state.username);
    urlencoded.append("password", this.state.password);

    var requestOptions = {
      method: "POST",
      headers: headers,
      body: urlencoded,
      redirect: "follow",
    };
    this.setState({
      loading: true
    });
    fetch(process.env.REACT_APP_API_LOGIN + "/auth", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result && result.status) {
          localStorage.setItem("jwt", result.data.access_token);
          localStorage.setItem("username", JSON.stringify(result.data.user));
          localStorage.setItem("stakes", JSON.stringify(result.data.stakes));
          localStorage.setItem("message", JSON.stringify(result.data.message));
          localStorage.setItem("client_casino_token", result.data.casino_token);
          localStorage.setItem("client_play_mods", JSON.stringify(result.data.event_type_settings));
          this.refreshCacheAndReload();
          let btns = [];
          for (let i = 1; i < 9; i++) {
            btns.push({
              b_name: result.data.stakes[`stake_name_${i}`],
              b_val: result.data.stakes[`stake_amount_${i}`]
            })
          }
          localStorage.setItem("btns", JSON.stringify(btns));
          this.props.navigate("/inplay?" + Math.random());
        } else {
          if (result && result.error && result.error.code === 1003) {
            localStorage.setItem("fcp", 1);
            localStorage.setItem("jwt", result.data.access_token);
            localStorage.setItem("username", this.state.username);
            localStorage.setItem("message", JSON.stringify(result.data.message));
            this.refreshCacheAndReload();
            this.props.navigate("/report/changepassword");
          } else {
            this.setState({
              has_error: true,
            });
            localStorage.removeItem("jwt");
          }

        }
        this.setState({
          loading: false
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          loading: false
        });
      });
  };

  logoMaker(flag = 0) {
    if (flag === 1) {
      return process.env.REACT_APP_NAME.charAt(0);
    } else {
      return process.env.REACT_APP_NAME.substring(1);
    }
  }

  refreshCacheAndReload = () => {
    if ('serviceWorker' in navigator) {
      serviceWorkerRegistration.unregister();
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      }).then(() => {
        serviceWorkerRegistration.register();
      })
    }
  }

  render() {
    return (
      <EventLayout>
        <div className="row mx-0 justify-content-center">
        <div className='col-md-6'>
          {this.state.loading && <Loader />}
          <div className="card card-signin theme-bg my-5">
            <div className="card-body">
              {/* <img src={logo} className="App-logo" alt="logo" /> */}
              <h1 className="App-logo">{this.logoMaker(1)}{this.logoMaker(0)}</h1>
              <hr style={{ "marginTop": 0, "marginBottom": 10 }} />
              <form
                onSubmit={(e) => this.handleClick(e)}
                className="ch-form"
                autoComplete={`${this.state.remember ? 'on' : 'off'}`}
              >
                <div className="form-label-group">
                  <input
                    id="inputEmail"
                    className="form-control"
                    placeholder="Email address"
                    required
                    autoFocus
                    value={this.state.username}
                    onChange={(event) => {
                      this.setState({
                        username: event.target.value,
                        has_error: false,
                      });
                    }}
                    autoComplete={`${this.state.remember ? 'on' : 'off'}`}
                  />
                  <label htmlFor="inputEmail">User Name</label>
                </div>
                <div className="form-label-group">
                  <input
                    type="password"
                    id="inputPassword"
                    className="form-control"
                    placeholder="Password"
                    required
                    value={this.state.password}
                    onChange={(event) => {
                      this.setState({
                        password: event.target.value,
                      });
                    }}
                    autoComplete={`${this.state.remember ? 'on' : 'new-password'}`}
                  />
                  <label htmlFor="inputPassword">Password</label>
                </div>
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                    value={this.state.remember}
                    checked={this.state.remember}
                    onChange={(e) => {
                      this.setState({
                        remember: !this.state.remember
                      }, () => {
                        localStorage.setItem("remember", e.target.value);
                      })
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                    Remember password
                  </label>
                </div>
                <button
                  className="btn btn-submit waves-effect waves-light"
                  type="submit"
                  disabled={this.state.loading}
                >
                  Sign in
                </button>
                {this.state.has_error && (
                  <div
                    className="mt-3 alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    wrong username or password
                  </div>
                )}
                <div className="form-label-group mt-3 d-none">
                  <a
                    className="col-6 text-theme bold d-none"
                    href={"mailto:" + EMAIL}
                  >
                    <MdEmail></MdEmail> Inquiry
                  </a>
                  <a
                    className="float-right text-right col-6 text-theme bold"
                    href={TELEGRAM}
                    target="_blank" rel="noreferrer"
                  >
                    <FaTelegram></FaTelegram> Telegram
                  </a>

                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
      </EventLayout>
    );
  }
}

export default Login;
